<template>
   <slot/>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>